import { css } from '@emotion/react';
import { almostWhite } from 'athena/styles/colors';
import { useMediaQuery } from 'react-responsive';
import { screenMdMin } from 'styles/global_defaults/media-queries';
import { standardSpacing } from 'styles/global_defaults/scaffolding';
import { useContext, useState } from 'react';
import { useSelector } from 'react-redux';
import { CompletionStatus, StepKey } from 'redux/schemas/models/mentoring-program-enrollments';
import { getSanitizedStyles } from 'shared/utils';
import { white } from 'styles/global_defaults/colors';
import { HomeProps } from '../types';
import { withOverflowFlexGrowClass } from '../../constants';
import ProgramGuidelinesAndExpectations from './program-guidelines-and-expectations';
import ConnectionDetails from './connection-details';
import MatchCelebration from './match-celebration';
import StepList from './step-list';
import NextSession from './sessions-list/next-session';
import SessionsList from './sessions-list';
import UnreleasedFeature from './unreleased-feature';
import ProgramClosed from './program-closed';
import PlanSessionFlyoutModal from './plan-session-flyout-modal';
import MentoringProgramContext from '../../context';


const withOverflowFlexGrowStyles = css`
  .${withOverflowFlexGrowClass} {
    flex: 1;
    min-height: 0;
  }
`;

const unreleasedStyles = (containerTranslate: number) => css`
  padding: 0;
  background-color: ${almostWhite};
  justify-content: center;
  .unreleased-section {
    width: 520px;
    max-height: 540px;
    height: calc(100% + ${120 - containerTranslate}px);
    transform: translateY(-${250 - containerTranslate}px);
    ${withOverflowFlexGrowStyles};
    background-color: ${white};
    border-radius: 10px;
    .description {
      max-width: 350px;
      text-align: center;
    }
  }
}`;

const styles = (isTablet: boolean, containerTranslate: number) => css`
  display: grid;
  align-items: center;
  height: calc(100vh - 150px);
  background-color: ${almostWhite};
  padding: 0 ${isTablet ? standardSpacing : 120}px;

  .participant-home {
    display: grid;
    gap: ${standardSpacing}px;
    grid-template-columns: auto 380px;
    height: calc(100% + ${120 - containerTranslate}px);
    transform: translateY(-${120 - containerTranslate}px);
    ${withOverflowFlexGrowStyles};

    .left-panel {
      gap: ${standardSpacing}px;
    }
  }
`;

const ParticipantHome = ({ scrollPercentage }: HomeProps) => {
  const isTablet = useMediaQuery({
    query: `(max-width: ${screenMdMin}px)`,
  });
  const containerTranslate = (90 / 100) * scrollPercentage;

  const [showPlanSessionFlyoutModal, setShowPlanSessionFlyoutModal] = useState(false);
  const [sessionToEdit, setSessionToEdit] = useState(null);

  const openPlanSessionFlyout = (session) => {
    setSessionToEdit(session);
    setShowPlanSessionFlyoutModal(true);
  };

  const { mentoringProgram } = useContext(MentoringProgramContext);
  const { endDate, releasedDate, mentorshipProgramEnrollment } = mentoringProgram || {};
  const isFeatureReleased = releasedDate && new Date() > new Date(releasedDate);
  const isProgramEnded = endDate && new Date() > new Date(endDate);

  const unreleasedFeatureStyles = (isFeatureReleased && !isProgramEnded)
    ? ''
    : unreleasedStyles(containerTranslate);

  const mentoringProgramEnrollment = useSelector(state => (
    state.models.mentoringProgramEnrollments[mentorshipProgramEnrollment]
  ));
  const { assignedConnections, states } = mentoringProgramEnrollment;
  const hasSingleConnection = assignedConnections?.length === 1;

  return (
    <div css={getSanitizedStyles([styles(isTablet, containerTranslate), unreleasedFeatureStyles])}>
      {isFeatureReleased && !isProgramEnded && (
        <>
          <div className='participant-home'>
            <div className='d-flex flex-column left-panel'>
              {states[StepKey.WAITING_FIRST_SESSION]?.status === CompletionStatus.COMPLETED ? (
                <SessionsList showPlanSessionFlyout={openPlanSessionFlyout} />
              ) : (
                <StepList showPlanSessionFlyout={openPlanSessionFlyout} />
              )}
              <ProgramGuidelinesAndExpectations />
            </div>
            <ConnectionDetails />
          </div>
          {hasSingleConnection && <MatchCelebration />}
        </>
      )}
      {!isFeatureReleased && (
        <UnreleasedFeature date={releasedDate} />
      )}
      {isProgramEnded && (
        <ProgramClosed />
      )}
      {showPlanSessionFlyoutModal && (
        <PlanSessionFlyoutModal
          session={sessionToEdit}
          onClose={() => {
            setSessionToEdit(null);
            setShowPlanSessionFlyoutModal(false);
          }}
        />
      )}
    </div>
  );
};

export default ParticipantHome;
