import t from 'react-translate';
import AliasSetting from './alias-setting';

const ProgramAliases = ({ aliases }) => (
  <div className='mb-6'>
    <div className='heading-5 mb-2'>
      {t.COURSE_ADMIN_MEGA_MENU.ALIASES.LABEL()}
    </div>
    <div className='gray-1 text-medium mb-2'>
      {t.MENTORING_PROGRAMS.SETTINGS.TABS.PROGRAM_SETUP.ALIASES.DESCRIPTION()}
    </div>
    {aliases.map(({ name, role }) => (
      <AliasSetting key={name} name={name} role={role} />
    ))}
  </div>
);

export default ProgramAliases;
