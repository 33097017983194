/* eslint-disable react/no-array-index-key */
import t from 'react-translate';
import { css } from '@emotion/react';
import { useCallback, useContext, useEffect, useRef } from 'react';
import {
  almostBlack,
  dividerMedium,
  lightBlack,
  shadedWhite,
} from 'athena/styles/colors';
import { doubleSpacing } from 'styles/global_defaults/scaffolding';
import { AngularContext } from 'react-app';
import MentoringProgramContext from '../../context';
import OrgLevelFieldsSection from './org-level';
import ProgramLevelFieldsSection from './program-level';

const styles = css`
  max-width: 820px;
  margin-left: auto;
  margin-right: auto;

  .description {
    color: ${almostBlack};
  }
  .subtitle {
    color: ${almostBlack};
  }
  .headers {
    color: ${almostBlack};
    height: 45px;
    border-bottom: 1px solid ${dividerMedium};
  }
  .field-row {
    height: ${doubleSpacing}px;
    border-bottom: 1px solid ${shadedWhite};

    .field-name {
      color: ${almostBlack};
    }
    .field-type {
      color: ${lightBlack};
    }
  }
`;

const ProgramProfileSetup = () => {
  const { mentoringProgram } = useContext(MentoringProgramContext);
  const { id: mentorshipProgramId } = mentoringProgram || {};
  const { $scope } = useContext(AngularContext);
  const deregisterStateChangeStartRef = useRef<any>(null);
  const programLevelSectionRef = useRef<any>(null);

  const sections = [
    {
      Section: OrgLevelFieldsSection,
    },
    {
      Section: ProgramLevelFieldsSection,
      ref: programLevelSectionRef,
    },
  ];

  const checkUnsavedChanges = useCallback(() => {
    const changes = programLevelSectionRef.current?.checkUnsavedChanges();
    return changes;
  }, []);

  useEffect(() => {
    deregisterStateChangeStartRef.current = $scope.StateManager.registerStateChangeStart(
      checkUnsavedChanges,
      'athena/templates/settings-navigate-away.html',
      'MENTORING_PROGRAMS.TEMPLATES.SETTINGS.NAVIGATE_AWAY.UNSAVED_CHANGES',
    );

    const { current: deregister } = deregisterStateChangeStartRef;

    return () => {
      deregister();
    };
  }, [checkUnsavedChanges, $scope.StateManager]);

  return (
    <div css={styles}>
      {mentorshipProgramId && (
        <div className='mb-4'>
          <div className='title heading-3 mb-2'>
            {t.MENTORING_PROGRAMS.SETTINGS.TABS.PROGRAM_PROFILE_SETUP.TITLE()}
          </div>
          <div className='description text-regular mb-6'>
            {t.MENTORING_PROGRAMS.SETTINGS.TABS.PROGRAM_PROFILE_SETUP.DESCRIPTION()}
          </div>
          {sections.map(({ Section, ref }, index) => (
            <Section key={index} ref={ref} />
          ))}
        </div>
      )}
    </div>
  );
};

export default ProgramProfileSetup;
