import { useContext, useEffect, useMemo, useState } from 'react';
import { css } from '@emotion/react';
import moment from 'moment';
import { useSelector } from 'react-redux';
import { useFormContext } from 'react-hook-form';
import t from 'react-translate';
import NvDatePicker, { DatePickerType } from 'shared/components/inputs/nv-datepicker';
import { getSettingsSaveStatus } from 'redux/selectors/mentoring-programs';
import { SaveStatus } from 'redux/schemas/app/mentoring-programs';
import NvTooltip from 'shared/components/nv-tooltip';
import MentoringProgramContext from '../../context';

const INPUT_DATE_FORMAT = 'MM/DD/YYYY hh:mm A';

const datePickerStyles = css`
  .react-datepicker-wrapper {
    width: 100%;
  }
`;

type ProgramDateType = {
  name: string;
  title: string;
  description: string;
  placeholder: string;
  minDate?: moment.Moment;
  maxDate?: moment.Moment;
  required?: boolean;
  disabled?: boolean;
  tooltip?: string;
  onSubmit?: () => void;
};

const ProgramDate = ({
  name,
  title,
  description,
  placeholder,
  minDate,
  maxDate,
  required = false,
  disabled = false,
  tooltip,
  onSubmit,
}: ProgramDateType) => {
  const { setValue } = useFormContext();
  const [dateValue, setDateValue] = useState(null);
  const saveStatus = useSelector(getSettingsSaveStatus);

  const handleChange = (date) => {
    setTimeout(() => {
      setDateValue(date);
    });
  };

  useEffect(() => {
    if (saveStatus !== SaveStatus.IN_PROGRESS) {
      if (dateValue) {
        dateValue.toString = () => dateValue.format(INPUT_DATE_FORMAT);
      }
      setValue(name, dateValue);
      onSubmit();
    }
  }, [dateValue]);

  return (
    <div className='d-flex col mb-2'>
      <div className='w-50 col'>
        <div className='d-flex w-100'>
          <strong>{title}</strong>
        </div>
        <div className='d-flex w-100'>{description}</div>
      </div>
      <div className='d-flex w-50 align-items-center pl-4'>
        <NvTooltip text={tooltip} enabled={!!tooltip}>
          <div className='w-100'>
            <NvDatePicker
              withForm
              min={minDate}
              max={maxDate}
              name={name}
              className='w-100'
              css={datePickerStyles}
              type={DatePickerType.DATETIME}
              placeholder={placeholder}
              required={required}
              disabled={disabled}
              onChange={handleChange}
            />
          </div>
        </NvTooltip>
      </div>
    </div>
  );
};

const ProgramDates = ({ onSubmit }) => {
  const { mentoringProgram } = useContext(MentoringProgramContext);
  const { releasedDate, endDate, matchAnnouncementDate } = mentoringProgram || {};

  const programDates: ProgramDateType[] = useMemo(() => [{
    name: 'releasedDate',
    title: t.MENTORING_PROGRAMS.SETTINGS.TABS.PROGRAM_SETUP.PROGRAM_DATES.RELEASE_DATE.TITLE(),
    description: t.MENTORING_PROGRAMS.SETTINGS.TABS.PROGRAM_SETUP.PROGRAM_DATES.RELEASE_DATE.DESCRIPTION(),
    placeholder: t.MENTORING_PROGRAMS.SETTINGS.TABS.PROGRAM_SETUP.PROGRAM_DATES.RELEASE_DATE.PLACEHOLDER(),
    maxDate: moment(endDate),
  }, {
    name: 'endDate',
    title: t.MENTORING_PROGRAMS.SETTINGS.TABS.PROGRAM_SETUP.PROGRAM_DATES.CLOSURE_DATE.TITLE(),
    description: t.MENTORING_PROGRAMS.SETTINGS.TABS.PROGRAM_SETUP.PROGRAM_DATES.CLOSURE_DATE.DESCRIPTION(),
    placeholder: t.MENTORING_PROGRAMS.SETTINGS.TABS.PROGRAM_SETUP.PROGRAM_DATES.CLOSURE_DATE.PLACEHOLDER(),
    minDate: matchAnnouncementDate ? moment(matchAnnouncementDate) : moment(releasedDate),
    disabled: !releasedDate,
    tooltip: !releasedDate ? t.MENTORING_PROGRAMS.SETTINGS.TABS.PROGRAM_SETUP.PROGRAM_DATES.CLOSURE_DATE.TOOLTIP() : '',
  }], [releasedDate, endDate]);

  return (
    <div className='mb-6'>
      <div className='heading-5 mb-2'>
        {t.MENTORING_PROGRAMS.SETTINGS.TABS.PROGRAM_SETUP.PROGRAM_DATES.TITLE()}
      </div>
      {programDates.map((programDate) => (
        <ProgramDate key={programDate.name} onSubmit={onSubmit} {...programDate} />
      ))}
    </div>
  );
};

export default ProgramDates;
