import axios from 'axios';
import t from 'react-translate';
import { createAction, createAsyncThunk } from '@reduxjs/toolkit';
import { addAlertMessage } from 'redux/actions/alert-messages';
import { AlertMessageType } from 'redux/schemas/app/alert-message';


type CreateMentoringProgramSessionParams = {
  programId: number;
  mentorshipProgramConnectionId: number;
  title: string;
  details?: string;
  scheduledDate: string;
};

export const createMentoringProgramSession = createAsyncThunk(
  'CREATE_MENTORING_PROGRAM_SESSION',
  async ({ programId, mentorshipProgramConnectionId, title, details, scheduledDate }: CreateMentoringProgramSessionParams, thunkAPI) => {
    const response = await axios.post(
      `/mentorship_programs/${programId}/sessions`,
      {
        mentorship_program_session: {
          mentorship_program_connection_id: mentorshipProgramConnectionId,
          title,
          details,
          scheduled_date: scheduledDate,
        },
      },
    );

    thunkAPI.dispatch(addAlertMessage({
      type: AlertMessageType.SUCCESS,
      header: t.FORM.SUCCESS_BANG(),
      message: t.MENTORING_PROGRAMS.PARTICIPANT_HOME.PLAN_SESSION_FLYOUT.ADD_SUCCESS_MESSAGE(),
    }));

    return response.data.result;
  },
);

export const getMentoringProgramSessions = createAsyncThunk(
  'GET_MENTORING_PROGRAM_SESSIONS',
  async ({ programId, connectionId, page, pageSize, filter }: any) => {
    // TODO: talk with backend about changing these names so that we can simplify the types
    const params: any = {
      page,
      page_size: pageSize,
      status: filter,
    };

    if (filter === 'past') {
      params.scheduled_date_desc = true;
    } else {
      params.scheduled_date_asc = true;
    }

    params.mentorship_program_connection_id = connectionId;

    const response = await axios.get(`/mentorship_programs/${programId}/sessions`, { params });

    return response.data.result;
  },
);

export const updateMentoringProgramSession = createAsyncThunk(
  'UPDATE_MENTORING_PROGRAM_SESSIONS',
  async ({ programId, sessionId, title, details, scheduledDate }: any) => {
    const response = axios.put(`/mentorship_programs/${programId}/sessions/${sessionId}.json`, {
      mentorship_program_session: {
        id: sessionId,
        title,
        details,
        scheduled_date: scheduledDate,
      },
    });

    return (await response).data.result;
  },
);

export const deleteMentoringProgramSession = createAsyncThunk(
  'DELETE_MENTORING_PROGRAM_SESSION',
  async ({ programId, sessionId }: any) => {
    const response = await axios.delete(`/mentorship_programs/${programId}/sessions/${sessionId}.json`, { params: { id: sessionId } });

    return response.data.result;
  },
);

export const getMentoringProgramSessionsCounts = createAsyncThunk(
  'GET_MENTORING_PROGRAM_SESSIONS_COUNTS',
  async ({ programId, connectionId }: any) => {
    const params = {
      mentorship_program_connection_id: connectionId,
    };

    const response = await axios.get(`/mentorship_programs/${programId}/sessions/count.json`, { params });

    return response.data.result;
  },
);

export const resetMentoringProgramSessionsList = createAction<{ resetList: boolean }>('RESET_MENTORING_PROGRAMS_SESSIONS_LIST');

