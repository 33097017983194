import { useContext, useMemo } from 'react';
import { OrgProfileField } from 'redux/schemas/models/account-fields';
import {
  MentorshipProgramProfileQuestion,
  OrgLevelQuestion,
} from 'redux/schemas/models/mentoring-program-profile-questions';
import { SaveStatus } from 'redux/schemas/app/mentoring-programs';
import { setSaveStatus } from 'redux/actions/mentoring-programs';
import { useAppDispatch } from 'redux/store';
import MentoringProgramContext from 'athena/components/mentoring-program/context';
import {
  MentorshipProgramOrgLevelQuestion,
  ProgramProfileQuestionType,
  createMentorshipProgramProfileQuestions,
  updateMentorshipProgramProfileQuestion,
} from 'redux/actions/mentoring-program-profile-questions';
import { wrapThunkAction } from 'redux/utils';
import OrgLevelFieldRow from './org-level-field-row';

type ChangeProps = OrgProfileFieldType & {
  isRequired: boolean
};

type OrgLevelFieldsProps = {
  orgLevel: OrgProfileField[];
  orgLevelQuestions: MentorshipProgramProfileQuestion[];
  reloadFields: () => void;
};

export type OrgProfileFieldType = OrgProfileField & {
  isUpdate?: boolean;
  programQuestionId?: number;
  isAttachedToRule?: boolean;
};

const OrgLevelFields = ({ orgLevel, orgLevelQuestions, reloadFields }: OrgLevelFieldsProps) => {
  const dispatch = useAppDispatch();
  const { mentoringProgram } = useContext(MentoringProgramContext);
  const { id: mentorshipProgramId } = mentoringProgram || {};

  const orgLevelFields: OrgProfileFieldType[] = useMemo(() => {
    const questions = orgLevelQuestions?.map(question => {
      const orgLevelQuestion = question.profileQuestion as OrgLevelQuestion;
      if (orgLevelQuestion) {
        return {
          id: orgLevelQuestion.profileSetting.id,
          isRequired: question.isRequired,
          programQuestionId: question.id,
          isAttachedToRule: question.isAttachedToRule,
        };
      } return null;
    }).filter(item => item !== null);
    const items = orgLevel.filter(currentField => !currentField.isHidden).map(currentField => {
      const item = questions?.find(q => q.id === currentField.id);
      if (item) {
        return {
          ...currentField,
          isUpdate: true,
          isRequired: item.isRequired,
          isAttachedToRule: item.isAttachedToRule,
          programQuestionId: item.programQuestionId,
        };
      }
      return currentField;
    });
    return items;
  }, [orgLevel, orgLevelQuestions]);

  const onChange = ({ questionId, isRequired, isUpdate, programQuestionId }: ChangeProps) => {
    let updateList = false;
    dispatch(setSaveStatus({ newStatus: SaveStatus.IN_PROGRESS }));
    const mentorshipProgramProfileQuestions: MentorshipProgramOrgLevelQuestion[] = [
      {
        type: ProgramProfileQuestionType.ORG_LEVEL,
        isRequired,
        questionId,
      },
    ];
    let dispatchAction;
    if (isUpdate) {
      const [mentorshipProgramProfileQuestion] = mentorshipProgramProfileQuestions;
      dispatchAction = dispatch(updateMentorshipProgramProfileQuestion({
        mentorshipProgramId,
        mentorshipProgramProfileQuestion,
        questionId: programQuestionId,
      }));
    } else {
      updateList = true;
      dispatchAction = dispatch(
        createMentorshipProgramProfileQuestions({
          mentorshipProgramId,
          mentorshipProgramProfileQuestions,
        }),
      );
    }
    wrapThunkAction(dispatchAction)
      .then(() => {
        dispatch(setSaveStatus({ newStatus: SaveStatus.COMPLETED }));
        if (updateList) reloadFields();
      })
      .finally(() => {
        setTimeout(() => {
          dispatch(setSaveStatus({ newStatus: null }));
        }, 3000);
      });
  };

  return (
    <>
      {orgLevelFields.map((field) => (
        <OrgLevelFieldRow
          key={`org-level-${field.id}`}
          field={field}
          onChange={isRequired => onChange({ ...field, isRequired })}
        />
      ))}
    </>
  );
};

export default OrgLevelFields;
